/**
 * Return wether a honeypot have a TCPA violation or not
 *
 * Note: This function does account for honeypots that are not being processed yet. No wrong flagging would happen.
 *
 * @param {Object} honeypot
 * @returns {Boolean} have TCPA violation or not
 */
export function isTCPA(honeypot) {
	if (honeypot.call_type !== 'Robocall') return false;
	let falseCount = 0;
	// falsy if false, if null then it's not falsy (not processed yet)
	if (honeypot.opt_out === false) {
		falseCount++;
	}
	// 'none' mean that the field was processed
	// null mean it wasn't - if not processed then we can't count it as falsy yet
	if (honeypot.callback_number === 'none') {
		falseCount++;
	}
	if (honeypot.business_name === 'none') {
		falseCount++;
	}
	return falseCount >= 2;
}

/**
 * Parse assembly result string, val or none string
 * If none it gonna be parsed as null
 * @param {String} val the value to parse
 * @returns {null|String} `null` if `val === 'none'` or `val`
 */
export function parseNoneStr(val) {
	return val === 'none' || val === 'null' ? null : val;
}

/**
 * Parse a boolean string (true or false) to boolean variable
 * @param {String} str the string to parse
 * @returns {Boolean} true or false
 */

export function parseBooleanStr(str) {
	// We can have this implementation if needed
	// return str.toLowerCase() in ['true', '1', 't', 'y', 'yes'];
	// This is more optimal
	return str === 'true';
}

/**
 * Compute percentage
 * @param {Number} val The part or subset value for which the percentage is to be calculated.
 * @param {Number} total The overall or total value that represents 100%.
 * @return {Number} The percentage value calculated as (val / total) * 100, rounded to the nearest whole number.
 */
export function computePercentage(val, total) {
	if (!total) {
		return 0;
	}
	return Math.round((val / total) * 100);
}

/**
 * Take a val and return val if defined (no null or undefined) or return 'N/A'
 * @param {any} val the value to parse
 * @return {any} val or 'N/A'
 */
export function valOrNA(val) {
	if (val === null || val === undefined) {
		return 'N/A';
	}
	return val;
}

/**
 * Return wether a value is defined or not
 * not defined if null or undefined
 * @param {any} val value to be tested if defined
 * @returns {Boolean} wether defined or not
 */
export function isDefined(val) {
	return val !== null && val !== undefined;
}

/**
 * Return wether a value is undefined or not
 * not defined if null or undefined
 * @param {any} val value to be tested if undefined
 * @returns {Boolean} wether undefined or not
 */
export function isUndefined(val) {
	return val === null || val === undefined;
}

/**
 * A filter to filter bad signers (words)
 * @param {String[]} signers list of signers to filter
 * @returns {String[]} filtered list of signers, with bad ones removed
 */
export function badSignersFilter(signers) {
	signers = signers || [];
	const listOfBadSignersNotToShow = ['Unknown', ''];
	return signers.filter(
		(signer) => !listOfBadSignersNotToShow.includes(signer)
	);
}

/**
 * A filter to filter bad user-agents (words)
 * @param {String[]} userAgentsList list of user agents to filter
 * @returns {String[]} filtered list of user agents, with bad ones removed
 */
export function badUserAgentsFilter(userAgentsList = []) {
	const listOfBadUserAgentsNotToShow = ['-', ''];
	return userAgentsList.filter(
		(userAgent) => !listOfBadUserAgentsNotToShow.includes(userAgent)
	);
}

/**
 * Take a value and return it, if a no null value, otherwise return 'No Information Available' instead
 * @param {string|undefined} val value to parse
 * @returns {string} val or 'No Information Available'
 */
export function valOrNoInfoAvailable(val) {
	return val || 'No Information Available';
}

/**
 * Take a count list object and return the desc sorted list if elements
 * @param {Record<string, number>} count_stat_obj count object <val, count>
 * @returns {string[]} List of desc sorted elements
 */
export function reduceCountListDesc(count_stat_obj) {
	if (!count_stat_obj) {
		return [];
	}

	return Object.entries(count_stat_obj)
		.sort(([_1, count1], [_2, count2]) => count2 - count1)
		.map(([campaign, _]) => campaign);
}

/**
 * Convert a number to commas separated representation string
 * @param {number} num number to convert
 * @returns {string} commas separated representation string
 */
export function numComma(num) {
	return num?.toLocaleString('en', { useGrouping: true }) || '';
}

/**
 * Applies a mathematical operation to the numeric part of a CSS size string and returns the result with the same unit.
 * @param {string} css_size - The CSS size string (e.g., "100px", "2rem").
 * @param {function} operation - The callback function that performs a mathematical operation on the number.
 * @returns {string} - The new CSS size string with the operation applied to the numeric part.
 */
export function opCssSize(css_size, operation) {
	// Regex to extract the numeric part and the unit
	const regex = /^([\d\.]+)(.*)$/;
	const match = regex.exec(css_size);

	if (!match) {
		throw new Error('Invalid CSS size format');
	}

	// Extract the number and the unit from the match
	const num_part = parseFloat(match[1]);
	const unit = match[2];

	// Apply the callback function to the numeric part
	const new_num_part = operation(num_part);

	// Reassemble and return the new CSS size
	return `${new_num_part}${unit}`;
}

/**
 * Converts a given number of seconds into a human-readable time format ("hh:mm:ss").
 * The function dynamically adjusts the format to omit higher units of time if they are zero.
 * For example, if there are no hours, only minutes and seconds will be displayed.
 *
 * @param {number} seconds - The total number of seconds to convert. This should be a non-negative integer.
 *
 * @returns {string} The formatted time string. Depending on the input, the format could be "hh:mm:ss", "mm:ss", or "ss".
 *                   Seconds are always displayed, while hours and minutes are displayed only when relevant.
 *                   The numbers are zero-padded to two digits.
 * @example
 * secondsToHuman(3661);
 * // returns "01:01:01"
 *
 * @example
 * secondsToHuman(61);
 * // returns "01:01"
 *
 * @example
 * secondsToHuman(59);
 * // returns "59"
 *
 * @example
 * secondsToHuman(0);
 * // returns "00"
 */
export function secondsToHuman(
	seconds,
	{ parts_min = 2, padding = [2, 2, 2] } = {}
) {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const remainingSeconds = seconds % 60;

	let timeParts = [];
	if (hours > 0 || parts_min >= 3) {
		timeParts.push(String(hours).padStart(padding[2], '0'));
	}
	if (minutes > 0 || hours > 0 || parts_min >= 2) {
		// Include minutes if there are hours, regardless of minute count
		timeParts.push(String(minutes).padStart(padding[1], '0'));
	}
	timeParts.push(String(remainingSeconds).padStart(padding[0], '0')); // Always include seconds

	return timeParts.join(':');
}

/**
 * Converts the first character of a string to uppercase while leaving the rest as is.
 *
 * @param {string} str - The input string.
 * @returns {string} The transformed string with the first character in uppercase and the rest left unchanged.
 */
export function strHeadline(str) {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

// Runtime Unique ID variable (would live through the whole runtime)
let last_id = 0;
/**
 * Generates a unique identifier in the same runtime.
 *
 * @return {number} A unique identifier.
 */
export function getRuntimeUniqueId() {
	last_id += 1;
	return last_id;
}
